:root {
	--dark-5: #000000;
	--dark-4: #0E0E0E;
	--dark-3: #1C1C1C;
	--dark-2: #29292B;
	--dark-1: #373739;
	--grey:   #454547;
	--light-1: #6A6A6C;
	--light-2: #8F8F91;
	--light-3: #B5B5B5;
	--light-4: #DADADA;
	--light-5: #FFFFFF;
	--font-color: #FFFFFF;
	--f1-red: #E81E02;
	--hyperlink: #0080FF;
	--primary: #0080FF;
	--standard-radius: 1rem;
	--small-radius: 0.5rem;
	--form-control-accent: #0080ff81;
	--form-control-accent-border: #0080FF;
	--form-control-accent-disabled: #105392;
	--form-control-accent-background: #003c7782;
}

* {
	box-sizing: border-box;
}
.App {
	background-color: var(--dark-4);
	height: 100dvh;
	color: var(--light-4);
	display: flex;
	flex-direction: column;
	align-items: stretch;
}


h1 {
	font-size: 1.8em;
	font-weight: 1000;
	color: var(--light-4);
	margin: 0.5em 0;
}

h2 {
	font-size: 1.5em;
	font-weight: 800;
	margin: 0;
	color: var(--grey);

}

h3 {
	font-size: 1.2em;
	font-weight: 800;
	margin-bottom: 0;
}

h4 {
	margin: 0;
}

p {
	margin: 0;
}
p.warning {
	color: var(--f1-red);
	font-size: 0.8rem;
}

label {
	font-size: 0.8rem;
	padding-left: 0.5rem;
	color: var(--light-2);
	font-weight: 600;
}

.subtext {
	font-size: 0.8rem;
	color: var(--light-2);
	/* font-weight: 600; */
}

.subtitle {
	display: flex;
	font-size: .8rem;
	gap: 0.5em;
	align-items: center;
	font-weight: 400;
}

form {
	display: flex;
	flex-direction: column;
	overflow: auto;
	max-width: 600px;
}

form header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

form section {
	display: flex;
	flex-direction: column;
	gap: 1em;
	/* overflow: auto; */
	flex-shrink: 1;
	padding-inline: 1em;
}

form footer {
	border-top: 1px solid var(--dark-2);
	display: flex;
	justify-content: flex-end;
	gap: 0.5em;
	padding: 1em;
}

.main-content {
	overflow: hidden;
	display: flex;
	flex-grow: 1;
}

.page-container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	/* padding: 1em; */
	overflow: auto;
	padding-top: calc(64px + 1em);
}

@media (min-width: 680px) {
	.page-container {
		justify-content: unset;
		align-items: stretch;
	}
	/* .page-container > * {
		animation: shift 0.15s forwards ease;
	} */
}

@keyframes shift {
	from { width: 100%; }
	to { width: 660px; }
}



img {
	font-size: 0.5em;
}

img.flag {
	height: 1.5em;
	aspect-ratio: 3/2;
	border-radius: 4px;
	object-fit: cover;
	box-shadow: 0px 3px 4px rgb(0 0 0 / 0.4);
}
img.flag.small {
	height: 1rem;
}

img.circuit-map {
	height: 3rem;
	aspect-ratio: 1;
	filter: invert() drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
	display: block;
	object-fit: contain;
}
img.circuit-map.small {
	height: 2rem;
}
img.track-map {
	width: clamp(100px, 100%, 500px);
}

img.avatar {
	display: inline-block;
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 50%;
	object-fit: cover;
	text-align: center;
	font-size: 1.3rem;
	font-weight: bold;
	box-shadow: 0px 1px 6px var(--dark-2);
}
img.img-preview {
	height: 6rem;
	width: 6rem;
	object-fit: cover;
	border-radius: 50%;
	border: 1px solid #ccc;
	text-align: center;
}
img.img-preview::before {
	display: block;
	margin-top: 34%;    /* magin number moving alt text to the center of the image */
}
img.poster-thumbnail {
	/* width: 100%; */
	height: 6rem;
	min-width: 6rem;
	object-fit: cover;
	border-radius: 5px;
}
img.tiny-overlapping-logo {
	margin-bottom: -0.5rem;
	margin-left: -0.5rem;
	border-radius: 1rem;
	max-height: 1.5rem;
	max-width: 1.5rem;
	background-color: black;
	padding: 2px;
}
img.tiny-overlapping-logo.square {
	object-fit: cover;
	height: 1.5rem;
	width: 1.5rem;
}
img.constructor-logo-inline {
	max-height: 1rem;
	max-width: 1.5rem;
}
.answer-image {
	width: 75px;
	height: 75px;
	object-fit: cover;
	border-radius: var(--standard-radius);
	flex-shrink: 0;
}

.overlapping-thumbnails {
	display: grid;
	grid-template-columns: max-content;
	grid-template-rows: max-content;
	grid-template-areas: 'img';
}
.overlapping-thumbnails > img {
	grid-area: img;
}
.overlapping-thumbnails > .overlap-thumbnail-container {
	grid-area: img;
	place-self: end end;
	display: flex;
	gap: -0.5rem;
}
.overlapping-thumbnails > .overlap-thumbnail-container > img {
	background-color: black;
	padding: 2px;
	margin-left: -0.5rem;
}

/* Img shapes */
img.rounded-square {
	border-radius: var(--standard-radius);
	object-fit: cover;
	aspect-ratio: 1;
}
img.rounded-rectangle {
	border-radius: var(--standard-radius);
	object-fit: contain;
	aspect-ratio: 1.6;
}
img.circle {
	border-radius: 50%;
	object-fit: cover;
	aspect-ratio: 1;
}
img.pill {
	object-fit: contain;
	aspect-ratio: 1.6;
}
img.pill.medium {
	border-radius: calc(3.5rem / 2);
}
img.pill.small {
	border-radius: calc(2rem / 2);
}
img.pill.tiny {
	border-radius: calc(1.5rem / 2);
}

/* Img sizes */
img.large {
	height: 6rem;
}
img.medium {
	height: 3.5rem;
}
img.small {
	height: 2rem;
}
img.tiny {
	height: 1.5rem;
}






/* 

.answer-image.medium {
	width: 64px;
	height: 64px;
}

.answer-image.small {
	width: 48px;
	height: 48px;
} */


/* --------------- */

.adder {
	margin: 1em;
	display: flex;
	align-items: center;
}
.adder:hover {
	cursor: pointer;
}

.adder-line {
	height: 1px;
	margin: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	color: #eee;
}

.adder:hover .adder-line {
	background-color: #777;
}

.adder-label {
	display: flex;
	align-items: center;
	color: #999;
	background-color: #333333;
	border-radius: 999em; /*40px 999em 999em 40px;*/
	height: 20px;
	text-align: center;
	font-size: 0.8em;
	line-height: 1.1em;
	padding: 0 10px;
	margin-bottom: 1px;
}


.subtle-frost {
	background-color: rgba(28, 28, 28, 0.8);
	backdrop-filter: blur(10px);
	color: var(--light-3);
}

.dark-card {
	display: flex;
	flex-direction: column;
	gap: 1em;
	overflow: hidden;
	flex-shrink: 0;
	padding-inline: 0;

	width: min(100%, 600px);
	border: 1px solid var(--dark-2);
	border-radius: var(--standard-radius);
	background-color: rgba(28, 28, 28, 0.5);
	/* background-color: var(--dark-3); */

	/* backdrop-filter: blur(10px); */
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
/* .dark-card h2 {
color: var(--grey);
} */
.dark-card h3 {
	margin: 0;
}
.dark-card.full-width {
	width: 100%;
}
.dark-card.scrollable {
	overflow: auto;
}
.dark-card.selectable:hover {
	cursor: pointer;
	border: 1px solid var(--light-5);
	box-shadow: 0 4px 12px rgba(170,170,170,0.15), 0 6px 10px rgba(170,170,170,0.12);
}
.dark-card.selected {
	border: 1px solid var(--form-control-accent-border);
	box-shadow: 0 8px 16px rgba(0,128,255,0.15), 0 6px 10px rgba(0,128,255,0.12);
	background-color: var(--form-control-accent-background);
}

.dark-card > * {padding-inline: 1.5rem;}
.dark-card > *:first-child {padding-top: 1.5rem;}
.dark-card > *:last-child {padding-bottom: 1.5rem;}

@media (max-width: 680px) {
	.dark-card > * {padding-inline: 1rem;}
	.dark-card > *:first-child {padding-top: 0.8rem;}
	.dark-card > *:last-child {padding-bottom: 0.8rem;}
}

.dark-card.note {
	padding: 0.5em;
	align-items: center;
	gap: 0;
	/* width: 100%; */
	border: 1px solid var(--dark-2);
	background-color: rgba(28, 28, 28, 0.5);	
	color: var(--light-2);
	font-size: 0.8rem;
}
.dark-card.note > * {
	padding: 0 !important;
	text-align: center;
}


.page-header {
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	align-items: center;
	margin-top: 0.5em;
	margin-bottom: 1em;
}
.page-header h2 {
	text-align: center;
	font-size: clamp(1em, 4vw, 1.5em);
}
/* .page-header > * {
flex-grow: 1;
} */

.hyperlink {
	color: var(--hyperlink);
	text-decoration: underline;
}

.hyperlink:hover {
	cursor: pointer;
}

.cursor-pointer:hover {
	cursor: pointer;
}

.background-image-cover {
	background-size: cover;
	background-position: center center;
	height: 10em;
}

.background-image-contain {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

/* Inputs */

.form-field {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}



ul.no-bullets {
	list-style-type: none;
}

/* Bootstrap */
.flex {display: flex;}
.flex-row {flex-direction: row;}
.flex.flex-column {flex-direction: column;}

.flex-wrap {flex-wrap: wrap;}
.flex.justify-content-between {justify-content: space-between;}
.flex.justify-content-start {justify-content: flex-start;}
.flex.justify-content-center {justify-content: center;}
.flex.justify-content-end {justify-content: flex-end;}
.flex.align-items-start {align-items: flex-start;}
.flex.align-items-center {align-items: center;}
.flex.align-items-end {align-items: flex-end;}
.flex.justify-self-end {justify-self: end;}

.flex-grow-0 {flex-grow: 0;}
.flex-grow-1 {flex-grow: 1;}
.flex-shrink-0 {flex-shrink: 0;}
.flex-shrink-1 {flex-shrink: 1;}
.align-self-start {align-self: flex-start;}
.align-self-end {align-self: flex-end;}

.flex-col-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.flex-1 {flex: 1;}


.inline-grid {display: inline-grid;}

.grid {display: grid;}
.justify-items-start {justify-items: start;}
.justify-items-center {justify-items: center;}
.align-self-center {align-self: center;}
.grid.grid-template-x {grid-template-areas: 'x';}
.grid.grid-area-x,
.inline-grid.grid-area-x {
	grid-area: x;
}
.grid.justify-self-end {justify-self: end;}

.gap-0 {gap: 0;}
.gap-1 {gap: 1em;}
.gap-05 {gap: 0.5em;}


.text-align-left {
	text-align: left !important;
}
.text-align-center {
	text-align: center !important;
}
.vertical-align-middle {
	vertical-align: middle;
}
.margin-inline-auto {
	margin-inline: auto;
}

.padding-0 {padding: 0 !important;}
.padding-05 {padding: 0.5em !important;}
.padding-1 {padding: 1em !important;}
.padding-bottom-0 {padding-bottom: 0;}
.padding-inline-1 {padding-inline: 1em !important;}

.margin-0 {margin: 0;}
.margin-05 {margin: 0.5em;}
.margin-1 {margin: 1em;}

.transparent {color: transparent;}

.w-100 {width: 100%;}

.single-line {
	white-space: nowrap;
	overflow: hidden;
	min-width: 0;
}
.single-line.shrink {
	overflow-wrap: break-word; /* Break long words */
}

.overflow-auto {overflow: auto;}
.overflow-x-auto {overflow-x: auto;}
.overflow-y-auto {overflow-y: auto;}

.overflow-hidden {overflow: hidden;}
.overflow-x-hidden {overflow-x: hidden;}
.overflow-y-hidden {overflow-y: hidden;}

.col-2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1em;
	/* overflow: hidden; */
	width: 100%; /* Ensure the container has a width */
	max-width: 100%; /* Prevent it from exceeding the parent width */
	box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
.col-2 > * {
	/* overflow: hidden; */
}

.divider {
	border-top: 1px solid #555;
	margin: 1em 0 !important;
	width: 100%;
}


.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}



ul.breadcrumb {
	margin: 0;
	padding: 0.5em 1em;
	list-style: none;
	display: flex;
}

ul.breadcrumb li {
	display: flex;
	align-items: center;
	font-size: 0.8em;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
	padding: 0.5em;
	content: "/\00a0";
	margin-left: 0.5em;
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
	color: #0275d8;
	text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
	color: #01447e;
	text-decoration: underline;
}

.back-btn {
	cursor: pointer;
}

.back-btn > svg {
	height: 0.7em;
	width: 0.7em;
}

.back-btn > span {
	padding-bottom: 2px;
	margin-left: 4px;
}



/* ------------------------------- */

.loader-show {
	position: absolute;
	top: 0;
	left: 0;
	height: 100dvh;
	width: 100dvw !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(10px) brightness(90%);
	z-index: 100;
}

.loader-hide {
	display: none;
}
.spinner-img {
	animation: spin 1s linear infinite;
	width: 75px;
}
@keyframes spin { 100% { transform:rotate(360deg); } }




/*------------------------------ */
table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	/* vertical-align: baseline; */
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


.table-container {
	overflow-y: visible;
	overflow-x: auto;
	width: 100% !important;
	margin-bottom: 1em;
}

.table {
	border-width: 0;
	color: white;
	margin: 0 auto;
	border-radius: var(--standard-radius);
	width: 100%;
	overflow: auto;
}
.table.table-layout-auto {
	table-layout: auto;
}
.table.table-layout-fixed {
	table-layout: fixed;
}

.table thead { 
	position: sticky; 
	top: 0; 
	border-bottom: 2px solid gray;
	z-index: 1;

	font-size: 0.8rem;
	padding-left: 0.5rem;
	color: var(--light-2);
	margin-bottom: -1rem;
	font-weight: 600;
	text-align: left;
}
.table.dark-2 thead { 
	background-color: var(--dark-2);
}
.table.dark-4 thead { 
	background-color: var(--dark-4);
}

.table tr {
	border-bottom: 1px solid gray;
	padding: 0 1em;
}

.table col[col-width="2em"] { width: 2em; }
.table col[col-width="max-content"] { width: max-content; }
.table col[col-width="fit-content"] { width: fit-content; }
.table col[col-width="long-text"] { width: 250px; }
.table col[col-width="medium-text"] { width: 210px; }
.table col[col-width="short-text"] { width: 125px; }
.table col[col-width="long-number"] { width: 90px; }
.table col[col-width="short-number"] { width: 65px; }

.table col[col-width="icon"] { width: 35px; }
.table col[col-width="auto"] { width: auto; }

.table td{
	padding: 4px;
}

.table .td-4em {
	width: 4em;
}

.table .td-10em {
	width: 10em;
}

.table .new-record {
	color: dodgerblue;
}

.delete-record * {
	color: red !important;
	text-decoration: line-through;
}