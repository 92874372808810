.profile {
    display: flex;
    flex-direction: column;
}


.avatar-section {
    margin-top: 1em;
    margin-left: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 680px) {
    .profile {
        flex-direction: row;
        animation: shift 0.15s forwards ease;
    }

    .avatar-section {
        margin-top: 4em;
        margin-left: 4em;
    }
}
.account {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

/* Badges */
.badge-container {
    display: flex;
    gap: 1em;
}
.badge {
    border-radius: 1em;
    background-color: var(--dark-section-bg-color);
    width: 3em;
    height: 3em;
}
.badge .counter {
    background-color: black;
    border-radius: 1em;
}
