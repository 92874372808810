.user-chip {
    display: flex;
    border-radius: 6px;
    padding: 12px;
    align-items: center;
    /* gap: 0.2em; */
    max-width: 300px;
    min-width: 150px;
    text-shadow: 2px 2px 8px var(--dark-2);
    box-shadow: inset rgba(0,0,0,1) 0 0 20px -4px;
    flex-grow: 0;
}

.user-chip[variant~="avatar"] {
    padding: 3px;
    border-radius: 100px;
    min-width: auto;
}


.user-chip[variant~="header"] {
    padding: 6px;
}

.user-chip .text-area {
    margin-left: 3px;
    overflow-x: hidden;
}

.user-chip .display-name {
    font-weight: 700;
    font-size: 1em;
    letter-spacing: 0.1ch;
    line-height: 2em;
    padding-left: 0.5em;
    padding-right: 1em;
    white-space: nowrap;
}

.user-chip .title {
    font-weight: 800;
    font-size: 1.1em;
    letter-spacing: 0.2ch;
    padding-right: 1em;
}

.user-chip[variant~="large"] .avatar {
    height: 6em;
    width: 6em;
}


.edit-icon {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    cursor: pointer;
    padding-right: var(--standard-radius);
}
.edit-icon svg {
    filter: drop-shadow(1px 1px 4px var(--dark-2));
}

/* Customizer */
.customizer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    margin: 1em 0;
}
/* .customizer label {
    font-weight: 600;
    margin-bottom: 0.2em;
} */

/* Color Picker */
.color-picker {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    padding: 0.5em 0;
}
.color-picker input[type="radio"] {
    display: none;
}
.color-swatch {
    width: 1.83em;
    height: 1.83em;
    border-radius: 4px;
    cursor: pointer;
    border: 3px solid transparent;
}
.color-picker input[type="radio"]:checked + label {
    border: 3px solid white;
}
