.settings-event-tab {
    overflow: auto;
    margin: 0 -1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    flex-grow: 1;
}

.settings-event-tab .event-container {
    overflow: auto;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin: 0 -1em;
    padding: 1em;
    flex-grow: 1;
}

.settings-event-tab .event-container .event {
    grid-template-columns: min-content auto;
    gap: 1em;
}
.settings-event-tab .event-container .dark-card > * {
    padding: 1rem !important;
}
/* .settings-event-tab .table {
    overflow: auto;
} */
