.stats-table {
  table-layout: fixed;
}
.stats-table td:first-child {
  padding-left: 0.5em;
}

.stats-table .subject-cell { 
  width: 100px; /* Adjust the width as needed */ 
  white-space: nowrap; 
}
.stats-table .subject-cell > div { 
  min-width: 300px;
}

.stats-table .stat-cell {
  vertical-align: bottom;
  font-size: 0.8em;
  /* padding-bottom: 1em; */
}