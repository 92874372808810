.schedule-settings-table {
    /* ID Column */
    th:nth-child(1),
    td:nth-child(1) { 
        width: 80px;
        text-align: center;
    }

    /* Type Column */
    th:nth-child(2),
    td:nth-child(2) { 
        width: 200px;
        text-align: left;
    }

    /* Date Column */
    th:nth-child(3),
    td:nth-child(3) { 
        width: 250px;
        text-align: left;
    }

    /* Meeting Key Column */
    th:nth-child(4),
    td:nth-child(4) { 
        width: 120px;
        text-align: left;
    }

    /* Session Key Column */
    th:nth-child(5),
    td:nth-child(5) { 
        width: 120px;
        text-align: left;
    }

    /* (Action) Column */
    th:nth-child(6),
    td:nth-child(6) { 
        width: 30px;
    }
}