.answer-container {
    gap: 0;
    justify-content: flex-end;
}

.answer-container .main {
    display: grid;

    grid-template-areas: 'details';
    grid-template-columns: auto;
    gap: 0;
    
    padding: 0.5em;
}

/* .driver[variant|="input"] .main {
    grid-template-areas: 'input details';
    grid-template-columns: 1em auto;
    column-gap: 0.5em;
} */


.answer-container .number {
    grid-area: input;
    justify-self: center;
}
/* .driver .input {
    grid-area: input;
    display: grid;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    place-self: center;
} */

.answer-container .points {
    grid-area: details;
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 6px;   /* Magin number. Adjust as needed */
}

.answer-container .answer {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    /* padding: 0 0.5em; */
}

.answer-container .answer h3 {
    /* font-size: 1.5em; */
    line-height: 1em;
}

.answer-container .details {
    grid-area: details;
    /* overflow: hidden; */
    /* padding-bottom: 0.5em; */
    /* align-items: center; */
}
.answer-container .answer-content {
    display: flex;
    gap: 0.3em;
    align-items: flex-end;
}