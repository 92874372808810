

.nav-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 100dvh;
    width: 100dvw;
    z-index: 99;
    
    background-color: rgba(0,0,0,0);
    backdrop-filter: blur(0);
    opacity: 0;
    visibility: hidden;
    transition: 
        background-color 0.2s ease,
        backdrop-filter 0.2s ease,
        opacity 0.2s ease,
        visibility 0.2s ease;
}
.backdrop-show {
    background-color: rgba(0,0,0,0.8);
    backdrop-filter: blur(12px);
    opacity: 1;
    visibility: visible;
}


.nav-container {
    --header-height: 64px;
    --nav-v-margin: 1em;
    --nav-h-margin: 1em;
    box-sizing: border-box;
    position: absolute;
    top: calc(var(--header-height) + 2 * var(--nav-v-margin));
    left: -300px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    /* padding-left:  */
    transition: left .15s ease;
}

.nav-card {
    width: 250px;
    padding: 0.5em;
    background: rgb(32, 33, 36);

    display: flex;
    flex-direction: column;
    z-index: 10;

    border-radius: var(--standard-radius);
    background-color: rgba(28, 28, 28, 0.5);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.nav-show {
    left: var(--nav-h-margin);
}


@media (max-width: 680px) {
    .nav-container {
      --nav-h-margin: var(--nav-v-margin);
    }
}

.nav-topbar {
    display: flex;
    height: 64px;
    align-items: center;
    color: white;
    margin: -2em;
    margin-bottom: 2em;
}
.nav-links {
    display: grid;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: 0.2em;
}

.nav-links li {
    padding: 0 1em;
    line-height: 2.5em;
    border-radius: calc(var(--standard-radius) / 2);
}
.nav-links > li:not(.divider):hover {
    /* color: #419cf8 !important; */
    cursor: pointer;
    background-color: var(--light-2);
}
.nav-links a {
    text-decoration: none;
    color: white;
}
.nav-links a:hover {
    color: #419cf8 !important;
    cursor: pointer;
}
.nav-links .current-page {
    background-color: var(--grey);
}