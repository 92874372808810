.markdown-container {
    /* max-width: 800px;
    margin: 0 auto;
    padding: 20px; */
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    /* line-height: 1.6; */
    /* color: #f8f7f7; */
    /* border-radius: 10px;
    font-size: 15px; */
}

.markdown-container h1, 
.markdown-container h2, 
.markdown-container h3 {
    /* color: #2a7ae2; */
    /* margin-top: 1.5em; */
    margin-bottom: 16px;
    line-height: 1.2;
}
.markdown-container h3 {
    margin-top: 2em;
}

.markdown-container h1 {
    font-size: 22px;
    color: #e22a33;
}

.markdown-container h2 {
    color: #d4af37;
    font-size: 16px;
    text-align: center;
}

.markdown-container h3 {
    font-size: 20px;
}

.markdown-container a {
    color: var(--hyperlink); 
    /* text-decoration: none;  */
    /* font-weight: bold; */
}

.markdown-container ul, 
.markdown-container ol {
    padding-left: 20px;
}

.markdown-container li { /* Text In Bullets */
    margin-bottom: 10px;
}

.markdown-container p { /* Regular Text  */
    text-align: justify;
    margin-bottom: 1em;
}

.rules .note {
    background-color: #2f2f2f;
    outline-color: #e6e6e6;
    color: #e6e6e6;
    word-wrap: break-word;
    word-break: break-word;
    border: 1px solid #fff0;
    border-radius: .375rem;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1rem;
    transition: height .5s ease-in,opacity .5s ease-in;
    display: block;
    position: relative;
}

.note.is-info {
    background-color: #3b2e58;
    border: 1px solid #3b2e58;
}

.note.is-info::before {
    content: "!";
    display: inline-block;
    width: 20px; /* Circle size */
    height: 20px; /* Circle size */
    border-radius: 50%;
    background-color: white; /* White circle */
    color: black; /* Color of the exclamation mark, adjust as needed */
    text-align: center;
    line-height: 20px; /* Adjust for vertical alignment */
    margin-right: 10px;
    font-weight: bold;
}


@media (max-width: 600px) {
    .markdown-container {
        max-width: 100%;
    }

    .markdown-container h1 {
        font-size: 20px;
    }

    .markdown-container h2 {
        font-size: 16px;
    }

    .markdown-container h3 {
        font-size: 12px;
    }
}

/* Add borders between table cells */
.markdown-container table {
    border-collapse: collapse;
  }
  
  .markdown-container th, .markdown-container td {
    border: 1px solid #ddd;
    padding: 6px;
    text-align: center;
    
  }
  
  