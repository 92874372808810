.summary-container {
    display: grid;
    grid-template-columns: max-content auto;
    gap: 1rem;
}

.summary-container h2 {
    line-height: 1em;
}

