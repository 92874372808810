
.event-details .section-header {
    color: var(--dark-1);
    font-size: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.validation-message {
    font-size: 0.8rem;
    color: red;
}

.submit-sheet {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.5em 1em;
    display: flex;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
    z-index: 98; /* Ensure the sheet stays on top of other elements */
    animation: slideIn 1s ease-out forwards;
    margin-bottom: -1em;
}
.submit-sheet .dark-card {
    background-color: var(--dark-4);
    border: 1px solid var(--form-control-accent);
    box-shadow: 0 1px 10px 3px var(--form-control-accent-background);
    /* flex-direction: row; */
    gap: 0;
    align-items: center;
    flex: 1;
    padding: 0.5em 1em !important;      /* Resetting .dark-card default styles */
}
.submit-sheet .dark-card > * {
    padding: 0 !important;      /* Resetting .dark-card default styles */
}
.submit-sheet p {
    /* padding: 0 !important; */
    text-align: center;
    /* padding-inline: 1em !important; */
    font-size: 0.85em;
    color: var(--light-2);
}

@keyframes slideIn {
    0% {
        transform: translateY(10em);
    }
    100% {
        transform: translateY(0);
    }
}