
/* .picks-submitted {
    width: 0.5em;
    height: 0.5em;
    color: green;
} */
/* .picks-submitted > svg {
    font-size: 1em;
    color: green;
} */
.picks-submitted {
    display: grid;
    align-content: center;
    margin-right: 2px;
}
.picks-submitted > svg {
    font-size: 1em;
    color: rgba(0, 255, 0, 0.8);
}
.form-complete {
    display: grid;
    align-content: center;
    /* margin-right: 2px; */
}
.form-complete > svg {
    font-size: 1.2em;
    /* color: rgba(0, 255, 0, 0.8); */
}
.picks-live-circle {
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: red;
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, .7);
    animation: glowing 1.5s infinite alternate ease-in-out;
    margin-right: 4px;
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 8px 2px rgba(255, 0, 0, 0.5);
    }
    100% {
        box-shadow: 0 0 8px 4px rgba(255, 0, 0, .7);
    }
}