.constructor-container {
    gap: 0;
    padding: 0 !important;
}

.constructor-container .main {
    display: grid;

    grid-template-areas: 'details';
    grid-template-columns: auto;
    gap: 0;
}

.constructor-container .number {
    grid-area: input;
    justify-self: center;
}

.constructor-container .name-row {
    display: grid;
    grid-template-columns: max-content minmax(auto, max-content) min-content auto;
    gap: 0.5em;
}

/* .constructor-container .constructor-name {
    margin-left: -1.5em;
} */

.constructor-container .constructor-points {
    grid-area: details;
    justify-self: end;
    align-self: end;
    display: flex;
    flex-direction: column;
    gap: 6px;   /* Magin number. Adjust as needed */
}


.constructor-container .constructor-answer {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    /* padding: 0 0.5em; */
}

.constructor-container .constructor-answer h3 {
    /* font-size: 1.5em; */
    line-height: 1em;
}

.constructor-container .constructor-details {
    grid-area: details;
    /* overflow: hidden; */
    /* padding-bottom: 0.5em; */
    align-items: center;
}

.constructor-container .projected-stats {
    font-style: italic;
    font-size: 0.8em;
}



.constructor-container .stats {
    grid-area: details;
    justify-self: end;
    align-self: start;
    display: flex;
    gap: 0.5em;
    align-items: center;
    padding-bottom: 1em;
}







.constructor-container .stats-accordian {
    grid-area: stat-accordian;
    transition: all 0.3s ease; /* Smooth transition */
    max-height: 0; /* Initially hidden */
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    padding-block: 0;
}
.constructor-container .stats-accordian.open {
  max-height: 600px; /* Adjust as needed */
  padding-block: 1em;
  border-top: 1px solid var(--dark-2);
}
@media (max-width: 680px) {
  .constructor-container .stats-accordian {
    grid-template-columns: 1fr;
  }
}